import React from "react";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";


export const MainPageVideo=()=>
    {
      const navigate=useNavigate()
      const goToVideos=()=>
      {
        navigate("/videos")
      }
        return(<>
        
        <div className="YouTubeouterbox " data-aos="fade-up">
            <h1 className="VideosHeading">Video Gallary</h1>
            <div className="row innerbox">
              <div className="col-lg-4 col-sm-12 videogallrybox1 my-5">
                <img
                  src={require("../../assets/images/videogallaryimag.png")}
                  className="videogallry1"
                />
              </div>
              <div className="col-lg-4 col-sm-12 my-5">
                <ul className="list-group list-group-flush rounded room-booking">
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#E1AC5B",
                        fontSize: "40px",
                        fontFamily: " Italianno, cursive",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      Welcome to Shreya Ashram
                    </strong>

                    <p
                      style={{
                        color: "#ff1212",
                        fontSize: "30px",
                        fontFamily: " Italianno, cursive",
                        textAlign: "center",
                        marginBottom: "0",
                      }}
                    >
                      Free Room Booking Inquiry
                    </p>

                    <hr
                      style={{
                        padding: "0",
                        marginTop: "0",
                        background: "yellow",
                        height: "2px",
                      }}
                    />
                  </li>
                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "30px",
                        paddingRight: "5px",
                        fontFamily: " Italianno, cursive",
                        letterSpacing: "2px",
                        textAlign: "center",
                        lineHeight: "1",
                        marginTop: "0",
                      }}
                    >
                      If you're interested in booking a free room at Ashram,
                      please contact us using the numbers below:
                    </p>
                  </li>

                  <hr
                    style={{
                      margin: "0 18%",
                      padding: "0",
                      background: "yellow",
                      height: "2px",
                    }}
                  />
                </ul>
              </div>

              <div className="col-lg-4 col-sm-12 videogallrybox1 my-5">
                <div className="videogallry2">
                  <img src={require("../../assets/images/videocard1.png")} onClick={goToVideos} style={{cursor:"pointer"}}/>
                  <img src={require("../../assets/images/videocard2.png")} onClick={goToVideos} style={{cursor:"pointer"}}/>
                  <img src={require("../../assets/images/videocard3.png")} onClick={goToVideos} style={{cursor:"pointer"}}/>
                </div>
              </div>
            </div>
          </div>
        
        </>)
    }