import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Navbar from "react-bootstrap/Navbar";
import maharajji from "../assets/images/Group.png";
import "./Dashbord.css";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import Dashbordsidebar from './Dashbordsidebar';
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import KathaUpdateButton from "./katha/UpdateButton";
import ImageUpdateButton from "./image/UpdateButton";
import VideoUpdateButton from "./videoGallery/UpdateButton";
import axios from "axios";

const Dashbord = () => {
  const [kathas, setKathas] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let response = await axios.get("https://vrindavanaashram.com/katha/last");
      setKathas(response.data);
      response = await axios.get("https://vrindavanaashram.com/image/last");
      console.table(response.data)
      setImages(response.data);
      response = await axios.get("https://vrindavanaashram.com/video/last");
      setVideos(response.data);
    } catch (error) {
      console.error(error);
      alert("Error fetching");
    }
  };
  return (
    <>
      <Navbar expand="lg" className="" style={{ backgroundColor: "#D9D9D9" }}>
        <Container fluid>
          <Navbar.Brand className="navtext">Dashbord</Navbar.Brand>

          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <Form className="d-flex">
              <div className="col-7 sometext">
                <img src={maharajji} height={60} />
              </div>
            </Form>
            <Button variant="danger" className="logoutbtn">
              Logout{" "}
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="dashboard-container">
        <div className="sidecontainer">
          <div>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Users</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/user/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/user/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Katha Bookings</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/katha/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/katha/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Room Booking</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/book/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/book/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Category</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/category/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/category/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Images</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/image/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/image/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>Videos</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/video/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/video/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>Rooms</Accordion.Header>
                <Accordion.Body>
                  <Button href="/admin/video/insert" variant="success">
                    Add
                  </Button>
                  <Button href="/admin/video/show" variant="primary">
                    Show
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="content">
          <Container className="card-container">
            <Row>
              <Col className="top-card-col">
                <Card style={{ width: "18rem" }}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>New Katha Bookings</ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
              <Col className="top-card-col">
                <Card style={{ width: "18rem" }}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Latest Videos Added</ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
              <Col className="top-card-col">
                <Card style={{ width: "18rem" }}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Latest Images Added</ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="card-col">
                <Card style={{ width: "18rem" }}>
                <ListGroup variant="flush">
                    {kathas.map((k, i) => {
                      return (
                        <ListGroup.Item key={i}>
                          <KathaUpdateButton
                            katha={k}
                            btnText={
                              k.user + " " + k.kathaDate + " " + k.kathaPlace
                            }
                          />
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card>
              </Col>
              <Col className="card-col">
                <Card style={{ width: "18rem" }}>
                <ListGroup variant="flush">
                    {images.map((img, i) => {
                      return (
                        <ListGroup.Item key={i}>
                          <ImageUpdateButton
                            imageGallery={img}
                            btnText={
                              img.imagetitle + " " + img.city + " " + img.place
                            }
                          />
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card>
              </Col>
              <Col className="card-col">
                <Card style={{ width: "18rem" }}>
                <ListGroup variant="flush">
                  {videos.map((v, i) => {
                      return (
                        <ListGroup.Item key={i}>
                          <VideoUpdateButton
                            videoGallery={v}
                            btnText={
                              v.videoTitle + " " + v.city + " " + v.place
                            }
                          />
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export { Dashbord };
