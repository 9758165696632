import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CalendarReact from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../assets/css/Calendar.css";
import axios from "axios";
const Calender2 = ({setKathas}) => {
  // console.log(kathas)
  const [date, setDate] = useState(new Date());
  const [specialDates, setSpecialDates] = useState([]);
 
  const navigate = useNavigate();
  const renderTileCss = ({ date, view }) => {
    // Check if the date is in the array of special dates
    if (specialDates.includes(date.toISOString().split("T")[0])) {
      return "special-date";
    }

    return null;
  };

  const renderTileContent = ({ date, view }) => {
    // Check if the date is in the array of special dates
    if (specialDates.includes(date.toISOString().split("T")[0])) {
      return (
        <div className="special-event">
          <div
            style={{
              fontSize: "1em",
              color: "white",
              backgroundColor: "#123456",
              borderRadius: "5px",
              margin: "0 5px",
              padding: "5px 10px",
            }}
          >
            <span>Radhe Govind....</span>
          </div>
          <div
            style={{
              fontSize: "1em",
              color: "gray",
              backgroundColor: "#333333",
              borderRadius: "5px",
              margin: "0 5px",
            }}
          >
            <i>Click to show details</i>
          </div>
        </div>
      );
    }

    return null;
  };


  useEffect(() => {
    fetchKathaDates();
  }, []);
function kathaDatesArray(datesArrayObj) {
  return datesArrayObj.map((dateObj)=>{
    return getCorrectDate(dateObj.kathaDate)})
}
  const fetchKathaDates = async () => {
    try {
      const response =  await axios.get("https://vrindavanaashram.com/katha/upcoming-dates");

      setSpecialDates(await kathaDatesArray(response.data));
    } catch (error) {
      console.error(error);
      alert("Error fetching users");
    }
  };
  function getCurrentDate(date) {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  function getCorrectDate(date) {
    date=new Date(date)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()-1).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  const fetchKathaByDate = async (date) => {
   
    date= await getCurrentDate(date);
    try {
      const response =  await axios.get("https://vrindavanaashram.com/katha/bookings-by-date?date="+date);

      setKathas(response.data);
    } catch (error) {
      console.error(error);
      alert("Error fetching users");
    }
  };
  return (
    <>
      <div className="calender-container">
        <div className="calendarshow container">
          <CalendarReact
            onClickDay={(date) => {
              // setDate(date);
              // navigate(`/specialdate/${date}`);
              // alert('Clicked day: ' + date.toDateString())
              fetchKathaByDate(date)
            }}
            value={date}
            tileClassName={renderTileCss}
            tileContent={renderTileContent}
          />
        </div>
      </div>
    </>
  );
};

export default Calender2;
