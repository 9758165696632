// UpdateForm.js
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormGroup, FormControl, FormLabel, FormText, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import "../../assets/css/admin/forms.css"
import { useLocation } from 'react-router-dom';

const UserUpdate = () => {
  const location=useLocation()
  const user=location.state.user
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.post(`https://vrindavanaashram.com/user/u/${user.id}`, values);
      alert('User updated successfully');
    } catch (error) {
      console.error(error);
      alert('Error updating user');
    }
    setSubmitting(false);
  };

  return (
    <div className='page-container'>
    <Formik
      initialValues={{ name: user.name, mobile: user.mobile, age: user.age, email: user.email, password: user.password }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required'),
        mobile: Yup.string().required('Mobile is required'),
        age: Yup.number().required('Age is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
          <Form  className='form-container'>
          <p className='display-5'>Update User</p>
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <Field name="name" as={FormControl} />
            {errors.name && touched.name && <FormText>{errors.name}</FormText>}
          </FormGroup>
          <FormGroup>
            <FormLabel>Mobile</FormLabel>
            <Field name="mobile" as={FormControl} />
            {errors.mobile && touched.mobile && <FormText>{errors.mobile}</FormText>}
          </FormGroup>
          <FormGroup>
            <FormLabel>Age</FormLabel>
            <Field name="age" type="number" as={FormControl} />
            {errors.age && touched.age && <FormText>{errors.age}</FormText>}
          </FormGroup>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <Field name="email" type="email" as={FormControl} />
            {errors.email && touched.email && <FormText>{errors.email}</FormText>}
          </FormGroup>
          <FormGroup>
            <FormLabel>Password</FormLabel>
            <Field name="password" type="password" as={FormControl} />
            {errors.password && touched.password && <FormText>{errors.password}</FormText>}
          </FormGroup>

          <Button type="submit" disabled={isSubmitting}>Update</Button>

          {isSubmitting && <Alert variant="info">Submitting...</Alert>}
        </Form>
      )}
    </Formik>
    </div>
  );
};

export { UserUpdate};
