import React, { useEffect, useMemo, useState } from "react";
import "../assets/css/MainGallary.css";

import { MainPageLocation } from "./indexPage/MainPageLocation";
import { MainProfileLinks } from "./indexPage/MainProfileLinks";
import { MainPageCopyright } from "./indexPage/MainPageCopyright";
import { ListGroup } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
function fetchImageFilters() {
  return new Promise(async (resolve, reject) => {
    try {
      const respose = await axios.get("https://vrindavanaashram.com/image/filters");
      resolve(respose.data);
    } catch (error) {
      reject(error);
    }
  });
}
const MainGallary = () => {
window.scrollTo(0, 0)

  const [imageGalleries, setImageGalleries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [year, setYear] = useState([]);
  const [place, setPlace] = useState([]);
  const [fullImgData, setFullImgData] = useState({});
  const [selectedKatha, setSelectedKatha] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPlace, setSelectedPlace] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [searchVal, setSearchVal] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function fetchImageGalleries() {
    try {
      setImageGalleries((await axios.get("https://vrindavanaashram.com/image")).data);
      // console.table(imageGalleries);
    } catch (error) {
      console.error(error);
      alert("Error fetching image galleries");
    }
  }

  async function fetchImageByValues(type) {
    // alert(type)
    switch (type) {
      case "city":
        try {
          setImageGalleries(
            (
              await axios.post(`https://vrindavanaashram.com/image/type`, {
                katha: selectedKatha,
                type: "city",
                city: selectedCity,
                year: selectedYear,
              })
            ).data
          );
          console.table(imageGalleries);
        } catch (error) {
          console.error(error);
          alert("Error fetching image galleries");
        }
      case "state":
        // const url=selectedKatha+"/"+type+"/"+selectedState+"/"+selectedYear+"-01-01"
        // console.log(url)
        try {
          setImageGalleries(
            (
              await axios.post(`https://vrindavanaashram.com/image/type`, {
                katha: selectedKatha,
                type: "state",
                state: selectedState,
                year: selectedYear,
              })
            ).data
          );

          console.table(imageGalleries);
        } catch (error) {
          console.error(error);
          alert("Error fetching image galleries");
        }
      case "doc":
        try {
          setImageGalleries(
            (
              await axios.post(`https://vrindavanaashram.com/image/type`, {
                katha: selectedKatha,
                type: "doc",
                doc: selectedYear,
              })
            ).data
          );

          console.table(imageGalleries);
        } catch (error) {
          console.error(error);
          alert("Error fetching image galleries");
        }
    }
  }

  async function fetchCategoryImage(id) {
    console.log(id);
    try {
      setImageGalleries(
        (await axios.get(`https://vrindavanaashram.com/image/category/${id}`)).data
      );

      console.table(imageGalleries);
    } catch (error) {
      console.error(error);
      alert("Error fetching image galleries");
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://vrindavanaashram.com/category");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchImageGalleries();

    fetchCategories();

    async function fetchImageFilters() {
      try {
        const response = await axios.get("https://vrindavanaashram.com/image/filters");
        setFilters(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchImageFilters();
    
    // console.table(imageGalleries)
  }, []);

  useEffect(() => {
    if (filters.length > 0) {
      setCity(filters[0].map((f) => f.city));
      setState(filters[1].map((f) => f.state));
      setPlace(filters[2].map((f) => f.place));
      setYear(filters[3].map((f) => f.year));
    }
  }, [filters]);

  // useEffect(() => {
  //   console.log('City:', city);
  //   console.log('State:', state);
  //   console.log('Place:', place);
  //   console.log('Year:', year);
  // }, [city, state, place, year]);

  const modalButton = (btnName, id) => (
    <>
      <div className="input-group mb-3" style={{ width: "100%" }}>
        <button
          type="button"
          className="btn btn-dark border-right-0 rounded-0"
          data-bs-toggle="modal"
          data-bs-target={"#" + id}
        >
          {btnName}
        </button>
        <span
          className="input-group-text bg-dark text-light border-0 rounded-0"
          id="basic-addon2"
          data-bs-toggle="modal"
          data-bs-target={"#" + id}
        >
          <i className="bi bi-search"></i>
        </span>
      </div>
    </>
  );
  const setFullImage = (imgData) => {
    setFullImgData(imgData);
    handleShow();
  };

  const imageModal = () => {
    return (
      <>
        <Modal show={show} fullscreen={true} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className="row">
              <div className="col">
                <Modal.Title>{"State:" + fullImgData.state}</Modal.Title>
              </div>
              <div className="col">
                <Modal.Title>{"City:" + fullImgData.city}</Modal.Title>
              </div>
              <div className="col">
                <Modal.Title>{"Place=" + fullImgData.place}</Modal.Title>
              </div>
              <div className="col">
                <Modal.Title>{"Date:" + fullImgData.doc}</Modal.Title>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <Image
              style={{ height: "100%", padding: "2%" }}
              src={"https://vrindavanaashram.com/images/" + fullImgData.imagepath}
            />
          </Modal.Body>
        
        </Modal>

      
      </>
    );
  };

  const searchModal = (id) => {
    switch (id) {
      case "by-state-modal":
        return (
          <div
            className="modal fade"
            id={id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Seacrh by State
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select Katha:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedKatha(event.target.value)}
                    >
                      <option value="">--Select Katha--</option>
                      {categories.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.c_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select State:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedState(event.target.value)}

                      // onChange="populateDistricts()"
                    >
                      <option value="">--Select State--</option>
                      {state.length > 0 &&
                        state.map((s, i) => (
                          <option key={i} value={s}>
                            {s}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select Year:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedYear(event.target.value)}

                      // onChange="populateDistricts()"
                    >
                      <option value="">--Select Year--</option>
                      {year.length > 0 &&
                        year.map((y, i) => (
                          <option key={i} value={y}>
                            {y}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => fetchImageByValues("state")}
                    className="btn btn-primary"
                  >
                    Seacrh
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      case "by-city-modal":
        return (
          <div
            className="modal fade"
            id={id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Seacrh by City
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select Katha:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedKatha(event.target.value)}
                    >
                      <option value="">--Select Katha--</option>
                      {categories.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.c_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="district">Select City:</label>
                    <select
                      id="district"
                      className="form-control"
                      onChange={(event) => setSelectedCity(event.target.value)}
                    >
                      <option value="">--Select City--</option>
                      {city.length > 0 &&
                        city.map((c, i) => (
                          <option key={i} value={c}>
                            {c}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select Year:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedYear(event.target.value)}
                    >
                      <option value="">--Select Year--</option>
                      {year.length > 0 &&
                        year.map((y, i) => (
                          <option key={i} value={y}>
                            {y}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => fetchImageByValues("city")}
                    className="btn btn-primary"
                  >
                    Seacrh
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      case "by-year-modal":
        return (
          <div
            className="modal fade"
            id={id}
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Seacrh by Year
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select Katha:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedKatha(event.target.value)}
                    >
                      <option value="">--Select Katha--</option>
                      {categories.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.c_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="state">Select Year:</label>
                    <select
                      id="state"
                      className="form-control"
                      onChange={(event) => setSelectedYear(event.target.value)}
                    >
                      <option value="">--Select Year--</option>
                      {year.length > 0 &&
                        year.map((y, i) => (
                          <option key={i} value={y}>
                            {y}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => fetchImageByValues("doc")}
                    className="btn btn-primary"
                  >
                    Seacrh
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <div className="gallaryshow">
        <div className=" mt-">
          <div className="row">
            <div className="ContainerGallary col">
              <div className="row">
                <div className="col-2 bg-secondary">
                  <div className="row mt-5">
                    <div className="col  mt-3">
                      <ListGroup className="category-list">
                        <ListGroup.Item
                          className="category-list-item"
                          key={0}
                          onClick={() => fetchImageGalleries()}
                        >
                          All
                        </ListGroup.Item>

                        {categories.map((category) => (
                          <ListGroup.Item
                            className="category-list-item"
                            key={category.id}
                            onClick={() => fetchCategoryImage(category.id)}
                          >
                            {category.c_name}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col  mt-5">
                      <h5 className="text-light text-center">
                        Search Images
                        <i className="bi bi-search"></i>
                      </h5>

                      <ListGroup>
                        <ListGroup.Item>
                          {modalButton("Search By State", "by-state-modal")}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {modalButton("Search By City", "by-city-modal")}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          {modalButton("Search By Year", "by-year-modal")}
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                  </div>
                </div>
                <div className="col-10" style={{ height: "100vh" }}>
                  <div className="row py-5">
                    {imageGalleries.map((image, index) => {
                      return (
                        <div
                          className="col-md-4 mt-2"
                          key={index}
                          style={{ height: "25vh" }}
                        >
                          <Image
                            style={{ height: "100%", padding: "2%" }}
                            src={
                              "https://vrindavanaashram.com/images/" + image.imagepath
                            }
                            onClick={() => setFullImage(image)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {searchModal("by-state-modal")}
              {searchModal("by-city-modal")}
              {searchModal("by-year-modal")}
              {imageModal()}
            </div>
          </div>
        </div>
      </div>

    
      <div className="location pt-5 bg-left">
        <MainPageLocation />
      </div>

   
      <div className="profilelinks">
        <MainProfileLinks />
      </div>

     
      <MainPageCopyright />
    </>
  );
};

export default MainGallary;
