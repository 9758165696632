// DeleteForm.js
import React from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const UserDelete = ({ id }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://vrindavanaashram.com/user/${id}`);
      alert('User deleted successfully');
    } catch (error) {
      console.error(error);
      alert('Error deleting user');
    }
  };

  return (
    <Button variant="danger" onClick={handleDelete}>Delete</Button>
  );
};

export  {UserDelete};
