// FetchForm.js
import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';

import {UserDelete} from './UserDelete';
import UpdateButton from './UpdateButton';

const UserFetchForm = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://vrindavanaashram.com/user');
      setUsers(response.data);
    } catch (error) {
      console.error(error);
      alert('Error fetching users');
    }
  };

  return (
    <div className='page-fetch'>
    <p className='display-5'>Images</p>
    <Table striped bordered hover className='form-container'>
      <thead>
        <tr>
          <th>Name</th>
          <th>Mobile</th>
          <th>Age</th>
          <th>Email</th>
          <th>Password</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <tr key={user.id}>
            <td>{user.name}</td>
            <td>{user.mobile}</td>
            <td>{user.age}</td>
            <td>{user.email}</td>
            <td>{user.password}</td>
            <td>
              {/* <UserUpdate user={user} /> */}
              <UpdateButton user={user}/>

              <UserDelete id={user.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
};

export  {UserFetchForm};
