import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { DeleteCategory } from './DeleteCategory';
import { UpdateCategory } from './UpdateCategory';
import UpdateButton from './UpdateButton';

export const FectchCategory = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://vrindavanaashram.com/category');
        
        setCategories(response.data);
      } catch (error) {
        console.error(error);
        alert('Error fetching categories');
      }
    };

    fetchData();
  }, []);

  return (
    <div className='page-fetch'>
    <p className='display-5'>Categories</p>
    <Table striped bordered hover className='form-container'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.c_name}</td>
              <td>
                <DeleteCategory category={category} />
                <UpdateButton category={category}/>

                {/* <UpdateCategory category={category} /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};


