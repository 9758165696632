import React from "react";
import "../../assets/css/style.css";

export const MainPageCopyright = () => {
  return (
    <>
      <div className="end bg-right h-100">
      
          &copy; 2024 Shreyashrey Ashram. All rights reserved.
        
      </div>
    </>
  );
};
