import React from 'react';
import axios from 'axios';

const DeleteButton = ({ id }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://vrindavanaashram.com/book/bookingUsers/${id}`);
      alert('Booking user deleted successfully');
    } catch (error) {
      console.error(error);
      alert('Error deleting booking user');
    }
  };

  return (
    <button onClick={handleDelete} className='btn btn-danger' style={{marginLeft:"5px",borderRadius:"5px"}}>Delete</button>
  );
};

export default DeleteButton;
