// UpdateForm.js
import React, { useEffect, useState } from "react";
import { Formik, Form, Field,ErrorMessage } from "formik";
import {useLocation} from "react-router-dom"
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
  Alert,
  Form as BootstrapForm,
  
} from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import "../../assets/css/admin/forms.css";

const KathaUpdate = () => {
  const location = useLocation();
  const katha = location.state.katha;
  console.log(katha)
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.post(`https://vrindavanaashram.com/katha/u/${katha.id}`, values);
      alert("Katha booking updated successfully");
    } catch (error) {
      console.error(error);
      alert("Error updating Katha booking");
    }
    setSubmitting(false);
  };
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://vrindavanaashram.com/category");
        setCategories(response.data);
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  return (
    <div className="page-container">
      <Formik
        initialValues={{
          kathaId: katha.kathaId,
          user: katha.user,
          days: katha.days,
          email: katha.email,
          mobile: katha.mobile,
          bookingDate: katha.bookingDate,
          kathaDate: katha.kathaDate,
          kathaPlace: katha.kathaPlace,
          kathaState: katha.kathaState,
          kathaCity: katha.kathaCity,
        }}
        validationSchema={Yup.object().shape({
          kathaId: Yup.string().required("Katha ID is required"),
          user: Yup.string().required("User is required"),
          days: Yup.number().required("Days is required"),
          email: Yup.string()
            .email("Invalid email")
            .required("Email is required"),
          mobile: Yup.string().required("Mobile No is required"),
          bookingDate: Yup.string().required("Boooking date is required"),
          kathaDate: Yup.string().required("Katha Date is required"),
          kathaPlace: Yup.string().required("katha Place is required"),
          kathaState: Yup.string().required("Katha State is required"),
          kathaCity: Yup.string().required("Katha City is required"),
        })}
        onSubmit={handleSubmit}
      >
      {({ isSubmitting, errors, touched }) => (
          <Form className="form-container">
            <p className="display-5">Book Katha</p>

            <BootstrapForm.Group controlId="kathaId">
            <FormLabel>Katha ID</FormLabel>
            <Field
              as="select"
              name="kathaId"
              style={{ padding: "5px 50px", color: "black" }}
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.c_name}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="kathaId"
              component={Alert}
              variant="danger"
            />
          </BootstrapForm.Group>
          <FormGroup>
              <FormLabel>Booking User</FormLabel>
              <Field name="user" as={FormControl} />
              {errors.user && touched.user && (
                <FormText>{errors.user}</FormText>
              )}
            </FormGroup>

            <BootstrapForm.Group controlId="days">
            <FormLabel>Katha Days</FormLabel>
            <Field
              as="select"
              name="days"
              style={{ padding: "5px 50px", color: "black" }}
            >
             
                <option key="1" value="">
               Select Days
                </option>
                <option key="2" value="7">
                7 Days
                </option>
                <option key="3" value="9">
                9 Days
                </option>
              
            </Field>
            <ErrorMessage
              name="kathaId"
              component={Alert}
              variant="danger"
            />
          </BootstrapForm.Group>

          <FormGroup>
              <FormLabel>Email</FormLabel>
              <Field name="email" type="email" as={FormControl} />
              {errors.email && touched.email && (
                <FormText>{errors.email}</FormText>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel>Mobile No.</FormLabel>
              <Field name="mobile" as={FormControl} />
              {errors.mobile && touched.mobile && (
                <FormText>{errors.mobile}</FormText>
              )}
            </FormGroup>
         
            <FormGroup>
            <FormLabel>Booking Date</FormLabel>
            <Field name="bookingDate" type="date" as={FormControl} />
            {errors.bookingDate && touched.bookingDate && <FormText>{errors.bookingDate}</FormText>}
          </FormGroup>
            <FormGroup>
            <FormLabel>Katha Date</FormLabel>
            <Field name="kathaDate" type="date" as={FormControl} />
            {errors.kathaDate && touched.kathaDate && <FormText>{errors.kathaDate}</FormText>}
          </FormGroup>

          <FormGroup>
              <FormLabel>Katha Place</FormLabel>
              <Field name="kathaPlace" as={FormControl} />
              {errors.kathaPlace && touched.kathaPlace && (
                <FormText>{errors.kathaPlace}</FormText>
              )}
            </FormGroup>
          <FormGroup>
              <FormLabel>Katha State</FormLabel>
              <Field name="kathaState" as={FormControl} />
              {errors.kathaState && touched.kathaState && (
                <FormText>{errors.kathaState}</FormText>
              )}
            </FormGroup>
          <FormGroup>
              <FormLabel>Katha City</FormLabel>
              <Field name="kathaCity" as={FormControl} />
              {errors.kathaCity && touched.kathaCity && (
                <FormText>{errors.kathaCity}</FormText>
              )}
            </FormGroup>

            <Button type="submit" disabled={isSubmitting}>
              Update
            </Button>

            {isSubmitting && <Alert variant="info">Submitting...</Alert>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { KathaUpdate };
