import React from 'react';
// import axios from 'axios';
import { BookingUpdateForm } from './UpdateBookings';
import { useNavigate } from 'react-router-dom';

const UpdateButton = ({bookingUser}) => {
    const naviate=useNavigate()
  const handleUpdate = async () => {
    naviate("/admin/book/update",{state:{bookingUser}})
  };

  return (
    <button onClick={handleUpdate} className='btn btn-success' style={{marginRight:"5px",borderRadius:"5px"}}>Update</button>
    // <BookingUpdateForm bookingUser={bookingUser} />
  );
};

export default UpdateButton;
