import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { RoomDelete } from './RoomDelete';
import { RoomUpdate } from './RoomUpdate';
import "../../assets/css/admin/forms.css"
import UpdateButton from './UpdateButton';

export const RoomFetchForm = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get('https://vrindavanaashram.com/room/');
        setRooms(response.data);
      } catch (error) {
        console.error(error);
        alert('Error fetching rooms');
      }
    };

    fetchRooms();
  }, []);

  return (
    <div className='page-fetch'>
    <p className='display-5'>Images</p>
    <Table striped bordered hover className='form-container'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Room Number</th>
            {/* <th>Booking User ID</th> */}
            <th>Booked</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rooms.map(room => (
            <tr key={room.id}>
              <td>{room.id}</td>
              <td>{room.roomNumber}</td>
              {/* <td>{room.bookind}</td> */}
              <td>{room.booked ? 'Yes' : 'No'}</td>
              <td>
                <RoomDelete id={room.id} />
                <UpdateButton room={room}/>
                {/* <RoomUpdate room={room} /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};


