import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormLabel, FormGroup, FormControl, FormText, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import "../../assets/css/admin/forms.css"

const BookingInsertForm = () => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await axios.post('https://vrindavanaashram.com/book/bookingUsers', values);
      alert('Booking user inserted successfully');
      resetForm();
    } catch (error) {
      console.error(error);
      alert('Error inserting booking user');
    }
    setSubmitting(false);
  };

  return (
    <div className='page-container'>
    <Formik
      initialValues={{
        name: '',
        mobile: '',
        roomId: '',
        email: '',
        password: '',
        checkInDate: '',
        checkOutDate: ''
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name is required'),
        mobile: Yup.string().required('Mobile is required'),
        roomId: Yup.string().required('Room ID is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
        checkInDate: Yup.date().required('Check-in Date is required'),
        checkOutDate: Yup.date().required('Check-out Date is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className='form-container'>
          <p className='display-5'>Add New Booking</p>
          <FormGroup>
            <FormLabel>Name</FormLabel>
            <Field name="name" as={FormControl} />
            {errors.name && touched.name && <FormText>{errors.name}</FormText>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Mobile</FormLabel>
            <Field name="mobile" as={FormControl} />
            {errors.mobile && touched.mobile && <FormText>{errors.mobile}</FormText>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Room ID</FormLabel>
            <Field name="roomId" as={FormControl} />
            {errors.roomId && touched.roomId && <FormText>{errors.roomId}</FormText>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Email</FormLabel>
            <Field name="email" as={FormControl} />
            {errors.email && touched.email && <FormText>{errors.email}</FormText>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Password</FormLabel>
            <Field name="password" type="password" as={FormControl} />
            {errors.password && touched.password && <FormText>{errors.password}</FormText>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Check-in Date</FormLabel>
            <Field name="checkInDate" type="date" as={FormControl} />
            {errors.checkInDate && touched.checkInDate && <FormText>{errors.checkInDate}</FormText>}
          </FormGroup>

          <FormGroup>
            <FormLabel>Check-out Date</FormLabel>
            <Field name="checkOutDate" type="date" as={FormControl} />
            {errors.checkOutDate && touched.checkOutDate && <FormText>{errors.checkOutDate}</FormText>}
          </FormGroup>

          <Button type="submit" disabled={isSubmitting}>ADD NEW BOOKING</Button>

          {isSubmitting && <Alert variant="info">Submitting...</Alert>}
        </Form>
      )}
    </Formik>
    </div>
  );
};

export {BookingInsertForm};
