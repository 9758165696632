import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const SpecialDate = () => {
  const param = useParams();
  const [kathas, setKathas] = useState({});
  useEffect(() => {
    fetchKatha();
  }, []);

  const fetchKatha = async () => {
    try {
      const response = await axios.get(
        "https://vrindavanaashram.com/katha/upcoming-bookings"
      );
      setKathas(response.data);
    } catch (error) {
      console.error(error);
      alert("Error fetching users");
    }
  };

  return (
    <>
      <h1>{param.date}</h1>
    </>
  );
};

export default SpecialDate;
