import React from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import "../../assets/css/admin/forms.css"

export const DeleteButton = ({ id }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://vrindavanaashram.com/video/${id}`);
      alert('Video gallery deleted successfully');
    } catch (error) {
      console.error(error);
      alert('Error deleting video gallery');
    }
  };

  return <Button variant="danger" onClick={handleDelete}>Delete</Button>;
};

