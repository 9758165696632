import React from 'react';
import { Button, Alert } from 'react-bootstrap';
import axios from 'axios';

export const DeleteCategory = ({ category }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://vrindavanaashram.com/category/${category.id}`);
      alert('Category deleted successfully');
    } catch (error) {
      console.error(error);
      alert('Error deleting category');
    }
  };

  return (
    
      <Button variant="danger" 
      className='btn btn-danger' style={{marginLeft:"5px",borderRadius:"5px"}}
      
      onClick={handleDelete}>Delete</Button>
    
  );
};


