import React, { useState } from "react";
import "../assets/css/DonateStyle.css";

import QRcode from "../assets/images/QR_code.png";

import { MainPageLocation } from "./indexPage/MainPageLocation";
import { MainPageCopyright } from "./indexPage/MainPageCopyright";

export const Donate = () => {
  return (
    <>
      <div className="bg-left">
        <h1 className="Headingh1">Donatation</h1>
        <div className="row p-5">
          <div className="col-lg-6 col-12">
           <div className="slider" style={{width:"70%"}}>
        <h1 className="Headingh1 display-5">Scan for Donate</h1>

            <div className="QRcodebox ">
              <div className="QRcode">
                <img src={QRcode} alt="QRCode" className="slider" />
              </div>
            </div>
            <h4 className="Headingh1 fs-1 text-danger">Donate Account is</h4>
            <h1 className="Headingh1 display-5">Thanks For Donate</h1>
            </div>
          </div>
          <div className="col-lg-6 col-12 px-5">
            <div
              id="carouselExampleCaptions"
              className="carousel slider-donate"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
            
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={require("../assets/images/Rooms4.jpeg")}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="Headingh1 display-3" style={{backgroundColor:"#5a5a128f"}}>Donate for Gaushala</h5>
                  
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={require("../assets/images/Rooms7.jpeg")}
                    className="d-block w-100"
                    alt="..."
                  />
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="Headingh1 display-3" style={{backgroundColor:"#5a5a128f"}}>Donate for Ashram</h5>
                   
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <MainPageLocation />

      <MainPageCopyright />
    </>
  );
};
