import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormGroup, FormControl, FormLabel, FormText, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import "../../assets/css/admin/forms.css"


export const RoomInsert = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.post('https://vrindavanaashram.com/room', values);
      alert('Room inserted successfully');
    } catch (error) {
      console.error(error);
      alert('Error inserting room');
    }
    setSubmitting(false);
  };

  return (
    <div className='page-container'>
    <Formik
      initialValues={{ roomNumber: '', booked: false }}
      validationSchema={Yup.object().shape({
        roomNumber: Yup.string().required('Room number is required'),
        // bookingUserId: Yup.string().required('Booking user ID is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className='form-container'>
        <p className='display-5'>Add New Room</p>
          <FormGroup>
            <FormLabel>Room Number</FormLabel>
            <Field name="roomNumber" as={FormControl} />
            {errors.roomNumber && touched.roomNumber && <FormText>{errors.roomNumber}</FormText>}
          </FormGroup>
          {/* <FormGroup>
            <FormLabel>Booking User ID</FormLabel>
            <Field name="bookingUserId" as={FormControl} />
            {errors.bookingUserId && touched.bookingUserId && <FormText>{errors.bookingUserId}</FormText>}
          </FormGroup> */}
          <FormGroup>
            <FormLabel>Booked</FormLabel>
            <Field name="booked" style={{marginLeft:"20px"}} type="checkbox"/>
          </FormGroup>

          <Button type="submit" disabled={isSubmitting}>Insert</Button>

          {isSubmitting && <Alert variant="info">Submitting...</Alert>}
        </Form>
      )}
    </Formik>
    </div>
  );
};

