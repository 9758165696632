import { Button} from 'react-bootstrap';
import axios from 'axios';

export const ImageDelete = ({ imageGallery, onDelete }) => {
    const handleDelete = async () => {
      try {
        await axios.delete(`https://vrindavanaashram.com/image/${imageGallery.id}`);
        onDelete(imageGallery.id);
        alert('Image gallery deleted successfully');
      } catch (error) {
        console.error(error);
        alert('Error deleting image gallery');
      }
    };
  
    return (
      <Button variant="danger" onClick={handleDelete}>
        Delete
      </Button>
    );
  };
  