import React from "react";
import "../../assets/css/style.css";
import instagram from "../../assets/images/instagram2.png";
import youtube from "../../assets/images/youtube.png";
import facebook from "../../assets/images/fecbookicon.png";
import Nav from "react-bootstrap/Nav";
import { Link, useNavigate } from "react-router-dom";
import BhgwanRam from "../../assets/images/Rectangle 39.png";
import Bhgwanshiv from "../../assets/images/Bhgwanshiv.png";
import BhgwanKrishn from "../../assets/images/BhgwanKrishn.png";
import maharajji from "../../assets/images/Group.png";

export const MainPageSlider = () => {
  const navigate = useNavigate();
  const goToKathBook = () => {
    navigate("/kathabook");
  };
  return (
    <>
      
        <div className="sliders py-5">
          <div className="row">
            <div className="col-lg-3 col-sm-12 d-flex justify-content-center align-items-center">
            
                <div
                  id="carouselExampleSlidesOnly"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img  src={BhgwanRam} width="100%" height="700" className="d-block" alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img src={Bhgwanshiv} width="100%" height="700" className="d-block" alt="..." />
                    </div>
                    <div className="carousel-item ">
                      <img src={BhgwanKrishn} width="100%" height="700" className="d-block" alt="..." />
                    </div>
                  </div>
                
                {/* <img alt="BhgwanRam" className="img1" />
                <img  alt="Bhgwanshiv" className="img2" />
                <img  alt="BhgwanKrishn" className="img3" /> */}
              </div>
            </div>

            <div className="col-lg-7 col-sm-6 sometext d-flex justify-content-center">
              {/* <p>Prabhu Ki Adbhut Katha Mahima</p> */}

              <img
                src={require("../../assets/images/Logo/logo1.png")}
                height={350}
              />
              <img src={maharajji} height={200} />
            </div>

            <div className="col-lg-2 col-sm-6 katha-img-btn">
            
                <img
                  src={require("../../assets/images/booking-ram-katha.png")}
                  onClick={goToKathBook}
                />

                <img
                  src={require("../../assets/images/booking-bhagwat-katha.png")}
                  onClick={goToKathBook}
                />

                <img
                  src={require("../../assets/images/booking-shiv-puran.png")}
                  onClick={goToKathBook}
                />
             
            </div>
        
        </div>
        {/* <!-- Left buttons --> */}
        <div>
          <div
            className="bookingbtns"
            style={{ position: "absolute", bottom: "10px",right: "10px" }}
          >
            <div className="sociallinks">
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="YouTube" />
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
