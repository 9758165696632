import React from "react";
// import axios from 'axios';
import { useNavigate } from "react-router-dom";

const UpdateButton = ({ katha,btnText }) => {
  const naviate = useNavigate();
  const handleUpdate = async () => {
    naviate("/admin/katha/update", { state: { katha } });
  };

  return (
    <button
      onClick={handleUpdate}
      className="btn btn-success"
      style={{ marginRight: "5px", borderRadius: "5px" }}
    >
    Update
    </button>
    // <BookingUpdateForm bookingUser={bookingUser} />
  );
};

export default UpdateButton;
