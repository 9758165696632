import { MainPageLocation } from "./indexPage/MainPageLocation";
import { MainPageRoomBookings } from "./indexPage/MainPageRoomBookings";
import "../assets/css/room.css";
// import { useEffect, useState } from "react";

export const RoomBooking = () => {
  window.scrollTo(0, 0);
  //   const [slideIndex, setSlideIndex] = useState(1);
  //   const slides = [
  //     {
  //       url: "../assets/images/bhajan3.jpg",
  //       alt: "Image 1",
  //     },
  //     {
  //       url: "../assets/images/bhajan4.jpg",
  //       alt: "Image 2",
  //     },
  //     {
  //       url: ,
  //       alt: "Image 3",
  //     },
  //     {
  //       url: "../assets/images/bhajan6.jpg",
  //       alt: "Image 4",
  //     },
  //     {
  //       url: "../assets/images/bgimage.jpg",
  //       alt: "Image 5",
  //     },
  //     {
  //       url: ,
  //       alt: "Image 6",
  //     },
  //   ];
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  //     }, 3000); // Change slide every 3 seconds

  //     return () => clearInterval(interval);
  //   }, []);
  //   const nextSlide = () => {
  //     setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  //   };

  //   const prevSlide = () => {
  //     setSlideIndex(
  //       (prevIndex) => (prevIndex - 1 + slides.length) % slides.length
  //     );
  //   };
  return (
    <>
      <div className="row bg-left py-3 sliders-div">
      <h1 className="Headingh1">Room Booking</h1>
        <div className="col-md-4 img text-center slider text-white pb-5 border-right-0">
          <i className="playfair-display fs-1 d-block">Normal Room</i>
          <i className="playfair-display fs-4 d-block">400/-</i>
          <div
            id="carouselExampleControls1"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={require("../assets/images/Rooms6.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../assets/images/Rooms2.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../assets/images/Rooms4.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls1"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls1"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="col-md-4 img text-center slider text-white pb-5">
          <i className="playfair-display fs-1 d-block">Cooler Room</i>
          <i className="playfair-display fs-4 d-block">800/-</i>
          <div
            id="carouselExampleControls2"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={require("../assets/images/Rooms6.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../assets/images/Rooms2.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../assets/images/Rooms4.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls2"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls2"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="col-md-4 img text-center slider text-white pb-5">
          <i className="playfair-display fs-1 d-block">AC Room</i>
          <i className="playfair-display fs-4 d-block">1000/-</i>
          <div
            id="carouselExampleControls3"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={require("../assets/images/Rooms6.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../assets/images/Rooms2.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../assets/images/Rooms4.jpeg")}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls3"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls3"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="col-md-12 img mt-3">
          <div className="row">
            <div className="col-3">
              <img
                src={require("../assets/images/goldenribbon.png")}
                className="d-block m-auto" width={"80%"}
              />
            </div>
            <div className="col-6">
              <img
                src={require("../assets/images/goldenribbon.png")}
                className="d-block m-auto" width={"80%"}
              />
            </div>
            <div className="col-3">
              <img
                src={require("../assets/images/goldenribbon.png")}
                className="d-block  m-auto" width={"80%"}
              />
            </div>
          </div>
        </div>
      </div>

      <MainPageRoomBookings />
      <div className="row-full bg-left">
        <MainPageLocation />
      </div>
    </>
  );
};
