import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import Calender2 from "../Calender2";
import axios from "axios";

export const MainPageKathaBookings = () => {
  return (
    <>
      <div className="CalenderDetails">
        <div className="Calendarimg">
          <div className="Calendarcontainer" id="BookKatha" data-aos="fade-up">
            <h1 className="Headingh1">Katha Bookings</h1>
            <InnerKathaBookingsPart />
          </div>
        </div>
      </div>
    </>
  );
};

export const InnerKathaBookingsPart = () => {
  const [kathas, setKathas] = useState([]);

  useEffect(() => {
    fetchKatha();
  }, []);

  const fetchKatha = async () => {
    try {
      const response = await axios.get(
        "https://vrindavanaashram.com/katha/upcoming-bookings"
      );
      setKathas(response.data);
    } catch (error) {
      console.error(error);
      alert("Error fetching users");
    }
  };

  const fetchKathaByCategory = async (id) => {
    try {
      const response = await axios.get(
        "https://vrindavanaashram.com/katha/category/" + id
      );
      setKathas(response.data);
    } catch (error) {
      console.error(error);
      alert("Error fetching users");
    }
  };

  const getLastDate = (d) => {
    let d1 = new Date(d);
    d1.setDate(d1.getDate() + 9);

    return d1.getDate() + "-" + (d1.getMonth() + 1) + "-" + d1.getFullYear();
  };

  const getFormatDate = (d) => {
    let d1 = new Date(d);
    d1.setDate(d1.getDate());

    return d1.getDate() + "-" + (d1.getMonth() + 1) + "-" + d1.getFullYear();
  };

  return (
    <>
      <div className="row innercal">
        <div className="col-lg-4 col-sm-12 d-flex align-items-center justify-content-center">
          <div className="calenderBox my-5 d-flex align-items-center justify-content-center">
            <Calender2 setKathas={setKathas} />
          </div>
        </div>
        <div
          className="col-lg-4 col-sm-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="dates-list-box">
            <div id="accordion">
              {kathas.map((b, i) => {
                return (
                  <div
                    className="card text-light"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "rgb(255,255,255,0.3)",
                    }}
                    key={i + 1}
                  >
                    <a
                      className="btn text-white"
                      data-bs-toggle="collapse"
                      href={"#collapse" + i}
                    >
                      <div className="card-header">
                        {b.category.c_name} {getFormatDate(b.kathaDate)}{" "}
                        {b.kathaState}
                        <div style={{ fontSize: "10px", color: "red" }}>
                          Click to Open
                        </div>
                      </div>
                    </a>
                    <div
                      id={"collapse" + i}
                      className="collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body bg-dark">
                        <li className="list-group-item text-center">
                          <strong
                            style={{
                              color: "#ff0E0E",
                              fontSize: "25px",
                            }}
                          >
                            {b.category.c_name}
                            <span
                              style={{ paddingLeft: "10px", color: "white" }}
                            >
                              {b.days} Days
                            </span>
                          </strong>

                          <br />
                          <strong
                            style={{
                              color: "#ff0E0E",
                              fontSize: "17px",
                            }}
                          >
                            {getFormatDate(b.kathaDate)}
                            <span style={{ color: "#ECB45F" }}> to </span>
                            {getLastDate(b.kathaDate)}
                          </strong>

                          <hr
                            style={{
                              margin: "0 18%",
                              padding: "0",
                              background: "yellow",
                              height: "2px",
                            }}
                          />
                          <strong>
                            <u>Address</u>
                            <br />
                            <span
                              style={{
                                color: "#ff0E0E",
                                fontSize: "25px",
                              }}
                            >
                              {b.kathaPlace}
                            </span>
                            ,
                            <span style={{ paddingLeft: "5px" }}>
                              {b.kathaCity}
                            </span>
                            ,
                            <span style={{ paddingLeft: "5px" }}>
                              {b.kathaState}
                            </span>
                          </strong>
                        </li>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <ul className="list-group list-group-flush rounded dates-list">
                    {kathas.map((b, i) => {
                      return (
                       
                          <li
                            key={i + 1}
                            className="list-group-item text-center"
                          >
                            <span className="sno-booking">{i + 1}</span>
                            <strong
                              style={{
                                color: "#ff0E0E",
                                fontSize: "25px",
                                paddingRight: "50px",
                              }}
                            >
                              {b.category.c_name}
                            </strong>
                            <span style={{ paddingRight: "50px" }}>
                              {b.days} Days
                            </span>
                            <strong
                              style={{
                                color: "#ff0E0E",
                                fontSize: "17px",
                                paddingRight: "5px",
                              }}
                            >
                             {getFormatDate(b.kathaDate)}
                              <span style={{ color: "#ECB45F" }}> to </span>
                              {getLastDate(b.kathaDate)}
                            </strong>

                            <hr
                              style={{
                                margin: "0 18%",
                                padding: "0",
                                background: "yellow",
                                height: "2px",
                              }}
                            />
                            <strong>
                              <u>Address</u> :
                              <span
                                style={{
                                  color: "#ff0E0E",
                                  fontSize: "25px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {b.kathaPlace}
                              </span>
                              ,
                              <span style={{ paddingLeft: "5px" }}>
                                {b.kathaCity}
                              </span>,
                              <span style={{ paddingLeft: "5px" }}>
                                {b.kathaState}
                              </span>
                            </strong>
                          </li>
                        
                      );
                    })}
                  </ul> */}
          </div>
        </div>

        <div className="col-lg-4 col-sm-12 detailsBox">
          <img
            src={require("../../assets/images/buttons/btnbookallkatha.png")}
            height={80}
            onClick={() => fetchKatha()}
          />
          <img
            src={require("../../assets/images/buttons/btnbookramkatha.png")}
            height={80}
            onClick={() => fetchKathaByCategory(1)}
          />
          <img
            src={require("../../assets/images/buttons/btnbookbhagwatkatha.png")}
            height={80}
            onClick={() => fetchKathaByCategory(2)}
          />

          <img
            src={require("../../assets/images/buttons/btnbookshivkatha.png")}
            height={80}
            onClick={() => fetchKathaByCategory(3)}
          />
        </div>
      </div>
    </>
  );
};
