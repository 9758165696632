import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormGroup, FormControl, FormLabel, FormText, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import "../../assets/css/admin/forms.css"
import { useLocation } from 'react-router-dom';


export const RoomUpdate = () => {
  const location=useLocation()
  const room=location.state.room
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.put(`https://vrindavanaashram.com/room/${room.id}`, values);
      alert('Room updated successfully');
    } catch (error) {
      console.error(error);
      alert('Error updating room');
    }
    setSubmitting(false);
  };

  return (
    <div className='page-container'>

    <Formik
      initialValues={{ roomNumber: room.roomNumber, bookingUserId: room.bookingUserId, booked: room.booked }}
      validationSchema={Yup.object().shape({
        roomNumber: Yup.string().required('Room number is required'),
        // bookingUserId: Yup.string().required('Booking user ID is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
         <Form  className='form-container'>
         <p className='display-5'>Update Room</p>
          <FormGroup>
            <FormLabel>Room Number</FormLabel>
            <Field name="roomNumber" as={FormControl} />
            {errors.roomNumber && touched.roomNumber && <FormText>{errors.roomNumber}</FormText>}
          </FormGroup>
          {/* <FormGroup>
            <FormLabel>Booking User ID</FormLabel>
            <Field name="bookingUserId" as={FormControl} />
            {errors.bookingUserId && touched.bookingUserId && <FormText>{errors.bookingUserId}</FormText>}
          </FormGroup> */}
          <FormGroup>
            <FormLabel>Booked</FormLabel>
            <Field name="booked" type="checkbox" style={{marginLeft:"20px"}} />
          </FormGroup>

          <Button type="submit" disabled={isSubmitting}>Update</Button>

          {isSubmitting && <Alert variant="info">Submitting...</Alert>}
        </Form>
      )}
    </Formik>
    </div>
  );
};


