import React, { useState } from "react";
import "../assets/css/style.css";

import { MainPageSlider } from "./indexPage/MainPageSlider";
import { MainPageCalender, MainPageRoomBookings } from "./indexPage/MainPageRoomBookings";
import { MainPageGallery } from "./indexPage/MainPageGallery";
import { MainPageVideo } from "./indexPage/MainPageVideo";
import { MainPageLocation } from "./indexPage/MainPageLocation";
import { MainProfileLinks } from "./indexPage/MainProfileLinks";
import { MainPageCopyright } from "./indexPage/MainPageCopyright";
import { MainPageKathaBookings } from "./indexPage/MainPageKathaBookings";
const Vrindavan = () => {
  const rooms = [
    "Room no.1",
    "Room no.2",
    "Room no.3",
    "Room no.4",
    "Room no.5",
    "Room no.6",
  ];



  const [modalShow, setModalShow] = useState(false);

  const handelSubmit = (e) => {
    e.preventDefault();
  };


  return (
    <>
      <div className="ashramsite">
        {/* Carousel  */}
        <MainPageSlider />
        {/* /////////////////Some Dates with Details////////////////// */}
        <MainPageKathaBookings />
        <MainPageRoomBookings />

        {/* ////////////////////Gallary Popular Searches///////////////////// */}
        <div className="gallarycontent">
          <MainPageGallery />
        </div>

        {/* ///////////////YouTube Popular Searches/////////////////// */}

        <div className="outerbox" id="Videos">
          <MainPageVideo />
        </div>

        {/* ///////////////////////// Vrindavan Free Stay Booking////////////// */}
        {/* <div className='vrindavnFreeStayBooking'>
                    <BookedRoomhtmlForm />
                </div> */}
        {/* //////////////////// Location //////////////////// */}
        <div className="location pt-5 bg-right">
          <MainPageLocation />
        </div>

        {/* //////////////////Profile////////////////////// */}
        <div className=" bg-left">
          <MainProfileLinks />
        </div>

        {/* /////////////////////////// */}
        <MainPageCopyright/>
      </div>
    </>
  );
};

export default Vrindavan;
