import React from "react";
import "../../assets/css/style.css";
import { MdCall, MdWhatsapp } from "react-icons/md";


export const MainPageLocation=()=>
    {
        return(<>
        
        <div className="maplocation" data-aos="fade-up">
            <div className="row bg-right">
        <h1 className="VideosHeading">Asharam Location</h1>

              <div className="col-lg-4 col-sm-12 locationbox my-5">
                <h1 className="maptext">Ashram Map</h1>
                <div className="map">
                  <img src={require("../../assets/images/map.png")} />
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 detailsBox my-5">
                <ul className="list-group list-group-flush rounded room-booking">
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#E1AC5B",
                        fontSize: "40px",
                        fontFamily: " Italianno, cursive",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      Welcome to Shreya Ashram
                    </strong>

                

                    <hr
                      style={{
                        padding: "0",
                        marginTop: "0",
                        background: "yellow",
                        height: "2px",
                      }}
                    />
                  </li>
                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "150%",
                        paddingRight: "5px",
                        fontFamily: " Italianno, cursive",
                        letterSpacing: "2px",
                        textAlign: "center",
                        lineHeight: "1",
                        marginTop: "0",
                      }}
                    >
                      If you're interested in booking Katha,
                      please contact us using the numbers below:
                    </p>
                  </li>
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#ff0E0E",
                        fontSize: "150%",
                       
                      }}
                    >
                      <MdCall
                        style={{
                          color: "lightgreen",
                          fontSize: "180%",
                         
                        }}
                      />
                      <MdWhatsapp
                        style={{
                          color: "lightgreen",
                          fontSize: "180%",
                         
                        }}
                      />
                      <br/>
                      <strong
                        style={{
                          color: "#E1AC5B",
                          fontSize: "80%",
                          fontFamily: "Italianno, cursive",
                          letterSpacing: "2px",
                        }}
                      >
                        +91-9897619231<br/>+91-7983887273
                      </strong>
                    </strong>
                  </li>

                  <hr
                    style={{
                      margin: "0 18%",
                      padding: "0",
                      background: "yellow",
                      height: "2px",
                    }}
                  />
                </ul>
              </div>
              <div className="col-lg-4 col-sm-12 my-5">
                <div className="mapinfo">
                  <div className="locationtext">
                    <span className="main-name">
                      Shree Krishna Shreyashray Ashram
                    </span>
                    , Tarash Mandir Colony, Vrindavan, Uttar Pradesh 281121
                  </div>
                  <div className="locationboxQR">
                    <img src={require("../../assets/images/mapQRcode.png")} />
                  </div>
                  <div className="locationtext">
                    <span className="main-scan">Scan me for location</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </>)
    }