// FetchForm.js
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import UpdateButton from './UpdateButton';
import { KathaDelete } from './KathaDelete';

const KathaFetchForm = () => {
  const [kathas, setKathas] = useState([]);

  useEffect(() => {
    fetchKatha();
  }, []);

  const fetchKatha = async () => {
    try {
      const response = await axios.get('https://vrindavanaashram.com/katha');
      setKathas(response.data);
    } catch (error) {
      console.error(error);
      alert('Error fetching users');
    }
  };

  return (
    <div className='page-fetch'>
    <p className='display-5'>Images</p>
    <Table striped bordered hover className='form-container'>
      <thead>
        <tr>
          {/* <th>id</th> */}
          <th>Katha ID</th>
          <th>User</th>
          <th>Days</th>
          <th>Email-ID</th>
          <th>Mobile No.</th>
          <th>Booking Data</th>
          <th>Katha Data</th>
          <th>Katha Place</th>
          <th>Katha State</th>
          <th>Katha City</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {kathas.map(katha => (
          <tr key={katha.id}>
            <td>{katha.kathaId}</td>
            <td>{katha.user}</td>
            <td>{katha.days}</td>
            <td>{katha.email}</td>
            <td>{katha.mobile}</td>
            <td>{katha.bookingDate}</td>
            <td>{katha.kathaDate}</td>
            <td>{katha.kathaPlace}</td>
            <td>{katha.kathaState}</td>
            <td>{katha.kathaCity}</td>
            <td>
              {/* <UserUpdate user={user} /> */}
              <UpdateButton katha={katha}/>

              <KathaDelete id={katha.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
};

export  {KathaFetchForm};
