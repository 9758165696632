import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
  Table,
} from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/admin/forms.css"

export const ImageUpdate = () => {
  const location=useLocation()
  const imageGallery=location.state.imageGallery
  const initialValues = {
    imagetitle: imageGallery.imagetitle,
    imagepath: imageGallery.imagepath,
    categoryId: imageGallery.categoryId,
    city: imageGallery.city,
    place: imageGallery.place,
    state: imageGallery.state,
    doc: imageGallery.doc,
    description: imageGallery.description,
  };

  const validationSchema = Yup.object({
    imagetitle: Yup.string().required("Image Title is required"),
    imagepath: Yup.string().required("Image Path is required"),
    categoryId: Yup.number().required("Category ID is required"),
    city: Yup.string().required("City is required"),
    place: Yup.string().required("Place is required"),
    state: Yup.string().required("State is required"),
    doc: Yup.date().required("Date is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("imagepath", values.imagepath);
      formData.append("imagetitle", values.imagetitle);
      formData.append("categoryId", values.categoryId);
      formData.append("city", values.city);
      formData.append("place", values.place);
      formData.append("state", values.state);
      formData.append("doc", values.doc);
      formData.append("description", values.description);

      await axios.put(`https://vrindavanaashram.com/image/${imageGallery.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // await axios.put(`https://vrindavanaashram.com/image/${imageGallery.id}`, values);
      alert("Image gallery updated successfully");
    } catch (error) {
      console.error(error);
      alert("Error updating image gallery");
    } finally {
      setSubmitting(false);
    }
  };
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://vrindavanaashram.com/category");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  const imageUrl = `https://vrindavanaashram.com/images/${imageGallery.imagepath}`;
  return (
    <div className='page-container'>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting,setFieldValue }) => (
        <Form  className='form-container'>
        <p className='display-5'>Update Image</p>

          <BootstrapForm.Group controlId="imagetitle">
            <FormLabel>Image Title</FormLabel>
            <Field as={FormControl} type="text" name="imagetitle" />
            <ErrorMessage
              name="imagetitle"
              component={Alert}
              variant="danger"
            />
          </BootstrapForm.Group>
          <BootstrapForm.Group controlId="imagepath">
            <FormLabel>Image Path</FormLabel>
            <div className="row">
              <div className="col">
              <Field as={FormControl} type="text"  value={imageGallery.imagepath} />

              </div>
              <div className="col">
              {imageGallery.imagepath && (
              <img src={imageUrl} alt="Current Image" style={{ maxWidth: '100px', maxHeight: '100px' }} />
            )}
              </div>
            </div>
           
            <ErrorMessage name="imagePath" component={Alert} variant="danger" />
          </BootstrapForm.Group>
          <BootstrapForm.Group controlId="imagePath">
            <FormLabel>Image Path</FormLabel>
            <input
              id="imagepath"
              name="imagepath"
              type="file"
              className="form-control-file"
              onChange={(event) => {
                setFieldValue("imagepath", event.currentTarget.files[0]);
              }}
            />
             
            <ErrorMessage name="imagePath" component={Alert} variant="danger" />
          </BootstrapForm.Group>
          
          <BootstrapForm.Group controlId="categoryId">
            <FormLabel>Category ID</FormLabel>
            <Field as="select" name="categoryId"  style={{ padding: "5px 50px", color: "black" }}>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.c_name}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="categoryId"
              component={Alert}
              variant="danger"
            />
          </BootstrapForm.Group>

          {/* <BootstrapForm.Group controlId="categoryId">
              <FormLabel>Category ID</FormLabel>
              <Field as={FormControl} type="text" name="categoryId" />
              <ErrorMessage name="categoryId" component={Alert} variant="danger" />
            </BootstrapForm.Group> */}

          <BootstrapForm.Group controlId="city">
            <FormLabel>City</FormLabel>
            <Field as={FormControl} type="text" name="city" />
            <ErrorMessage name="city" component={Alert} variant="danger" />
          </BootstrapForm.Group>

          <BootstrapForm.Group controlId="place">
            <FormLabel>Place</FormLabel>
            <Field as={FormControl} type="text" name="place" />
            <ErrorMessage name="place" component={Alert} variant="danger" />
          </BootstrapForm.Group>

          <BootstrapForm.Group controlId="state">
            <FormLabel>State</FormLabel>
            <Field as={FormControl} type="text" name="state" />
            <ErrorMessage name="state" component={Alert} variant="danger" />
          </BootstrapForm.Group>

          <BootstrapForm.Group controlId="doc">
            <FormLabel>Date</FormLabel>
            <Field as={FormControl} type="date" name="doc" />
            <ErrorMessage name="doc" component={Alert} variant="danger" />
          </BootstrapForm.Group>

          <BootstrapForm.Group controlId="description">
            <FormLabel>Description</FormLabel>
            <Field as={FormControl} type="text" name="description" />
            <ErrorMessage
              name="description"
              component={Alert}
              variant="danger"
            />
          </BootstrapForm.Group>

          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </Form>
      )}
    </Formik>
    </div>
  );
};
