import React from 'react';
// import axios from 'axios';

import { useNavigate } from 'react-router-dom';

const UpdateButton = ({imageGallery,btnText}) => {
    const naviate=useNavigate()
  const handleUpdate = async () => {
    naviate("/admin/image/update",{state:{imageGallery}})
  };

  return (
    <button onClick={handleUpdate} className='btn btn-success' style={{marginRight:"5px",borderRadius:"5px"}}>{btnText}</button>
    // <BookingUpdateForm bookingUser={bookingUser} />
  );
};

export default UpdateButton;
