import React from "react";
import "../../assets/css/style.css";
import instagram from "../../assets/images/instagram2.png";
import youtube from "../../assets/images/youtube.png";
import facebook from "../../assets/images/fecbookicon.png";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { MdWifiCalling3 } from "react-icons/md";
import profileImage from "../../assets/images/maharajpohoto.jpg"

export const MainProfileLinks = () => {
  return (
    <>
      <div className="row py-5 ps-3">
        <div className="col-lg-4 col-sm-12 d-flex align-items-center justify-content-center">
          <div className="bg-left links-col p-5" style={{border:"3.5vw solid black",borderRadius:"1vw"}}>
          <Nav.Link href="" as={Link} to={"/"}>
            {" "}
            <h1 className="links">Home</h1>
          </Nav.Link>
          <Nav.Link href="" as={Link} to={"/BookKtha"}>
            {" "}
            <h1 className="links">Katha Book</h1>
          </Nav.Link>
          <Nav.Link href="" as={Link} to={"/BookRoom"}>
            <h1 className="links">Room Book</h1>
          </Nav.Link>
          <Nav.Link href="" as={Link} to={"/About"}>
            {" "}
            <h1 className="links">About Maharaj ji</h1>
          </Nav.Link>
          </div>
        </div>

        {Profile()}
      </div>
    </>
  );
};


export const Profile=()=>
{
  return(<>
<div className="col-lg-4 col-sm-12 py-5">
          <div className="h-100 bg-right bio-col flex-column p-5">
          <div class="display-5 text-light text-center">Acharya Shree Krishnadas Ji</div>
          <hr style={{backgroundColor:"white"}}/>
            <ul class="list-group bg-left">
            
              <li class="list-group-item">A second item</li>
              <li class="list-group-item">A third item</li>
              <li class="list-group-item">A fourth item</li>
              <li class="list-group-item">And a fifth one</li>
            </ul>
            <ul class="list-group bg-left mt-3">
            
              <li class="list-group-item">A second item</li>
              <li class="list-group-item">A third item</li>
              <li class="list-group-item">A fourth item</li>
              <li class="list-group-item">And a fifth one</li>
            </ul>
          </div>
        </div>

        <div className="col-lg-4 col-sm-12" style={{ height: "100%" }}>
          <div className="row profile-links bg-left pt-2">
            <div className="col-12 img-col">
              <div className="p-3 bg-light" style={{ height: "80%" }}>
                <img src={profileImage} />
                <h3 className="profilename">Acharya Krishnadas Ji</h3>
              </div>
            </div>
            <div className="col-12 contact-col">
              <div className="row bg-right">
                <div className="col-6">
                  <div className=" p-3 d-flex flex-column">
                    <h1 className="p-0 m-0" style={{ fontSize: "200%" }}>
                      Contacts
                    </h1>
                    <div>
                      <MdWifiCalling3 style={{ color: "white" }} /> 9897619231
                    </div>
                    <div>
                      {" "}
                      <MdWifiCalling3 style={{ color: "white" }} /> 7983887273
                    </div>
                  </div>
                </div>
                <div className="col-6 pt-3">
                  <div className="socialmedialink">
                    <a href="https://www.facebook.com/share/2efGnEcaAooHYyQR/?mibextid=qi2Omg">
                      <img src={facebook} alt="Facebook" />
                      <h1>Shre Krishna Shreyashrey Ashram</h1>
                    </a>

                    <a href="https://www.youtube.com/@acharyakrishnadasofficial7502/videos">
                      <img src={youtube} alt="YouTube" />
                      <h1>Acharya krishnadas Official</h1>
                    </a>
                    <a href="https://www.instagram.com/">
                      <img src={instagram} alt="Instagram" />
                      <h1>Acharya Krishnadas Ji</h1>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</>
  )
}