
// import Slider from "./Pages/ImageSlider/Slider";
import Main from "./Pages/Main";


function App() {
  return (
    <>
    <Main/> 
    {/* <Slider/> */}
    </>
  );
} 

export default App;
