import React  from "react";
import "../../assets/css/style.css";
import gallaryimg1 from "../../assets/images/gallaryimagepic.jpg";
import gallaryimg2 from "../../assets/images/gallaryshram.jpg";
import gallaryimg3 from "../../assets/images/aahramRoom.jpg";
import { useNavigate } from "react-router-dom";


export const MainPageGallery =()=>
    {
      const navigate=useNavigate();
      const goToGallery=()=>
      {
        alert()
        navigate("/gallary")
      }
        return(<>
        
        <div className="gallary" id="Photos" data-aos="fade-up">
            <h1 className="GallaryHeading">Image Gallary</h1>
            <div className="row imagegallary">
              <div className="col-lg-4 col-sm-12">
                <ul className="list-group list-group-flush rounded room-booking">
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#E1AC5B",
                        fontSize: "40px",
                        fontFamily: " Italianno, cursive",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      Welcome to Shreya Ashram
                    </strong>

                    <p
                      style={{
                        color: "#ff1212",
                        fontSize: "30px",
                        fontFamily: " Italianno, cursive",
                        textAlign: "center",
                        marginBottom: "0",
                      }}
                    >
                      Free Room Booking Inquiry
                    </p>

                    <hr
                      style={{
                        padding: "0",
                        marginTop: "0",
                        background: "yellow",
                        height: "2px",
                      }}
                    />
                  </li>
                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "30px",
                        paddingRight: "5px",
                        fontFamily: " Italianno, cursive",
                        letterSpacing: "2px",
                        textAlign: "center",
                        lineHeight: "1",
                        marginTop: "0",
                      }}
                    >
                      If you're interested in booking a free room at Ashram,
                      please contact us using the numbers below:
                    </p>
                  </li>

                  <hr
                    style={{
                      margin: "0 18%",
                      padding: "0",
                      background: "yellow",
                      height: "2px",
                    }}
                  />
                </ul>
              </div>
              <div className="col-lg-4 col-sm-12 gallaryimagebox my-5">
                <div className="gallaryimage">
                <div
                  id="carouselExampleSlidesOnly1"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img   src={gallaryimg1} width="100%" height="300" className="d-block" alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img src={gallaryimg2}
                     width="100%" height="300" className="d-block" alt="..." />
                    </div>
                    <div className="carousel-item ">
                      <img  src={gallaryimg3}
                    width="100%" height="300" className="d-block" alt="..." />
                    </div>
                  </div>
                </div>
                 
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 my-5 gallaryimagebtn">
                
                  
                  <img  onClick={goToGallery} style={{cursor:"pointer"}}
                    src={require("../../assets/images/imagegallarycard1.png")}
                   
                  />
                  <img
                  style={{cursor:"pointer"}}
                    src={require("../../assets/images/imagegallarycard2.png")}
                    onClick={goToGallery}
                  />
                  <img
                  style={{cursor:"pointer"}}
                    src={require("../../assets/images/imagegallarycard3.png")}
                    onClick={goToGallery}
                  />
                
              </div>
            </div>
          </div>
        </>)
    }