import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormGroup, FormControl, FormLabel, FormText, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const UpdateCategory = () => {
  const location=useLocation()
  const category=location.state.category
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.put(`https://vrindavanaashram.com/category/${category.id}`, values);
      alert('Category updated successfully');
    } catch (error) {
      console.error(error);
      alert('Error updating category');
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ c_name: category.c_name }}
      validationSchema={Yup.object().shape({
        c_name: Yup.string().required('Category name is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <FormGroup>
            <FormLabel>Category Name</FormLabel>
            <Field name="c_name" as={FormControl} />
            {errors.c_name && touched.c_name && <FormText>{errors.c_name}</FormText>}
          </FormGroup>

          <Button type="submit" disabled={isSubmitting}>Update</Button>

          {isSubmitting && <Alert variant="info">Submitting...</Alert>}
        </Form>
      )}
    </Formik>
  );
};


