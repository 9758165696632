import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
} from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import "../../assets/css/admin/forms.css";
import { useLocation } from "react-router-dom";

export const VideoGalleryUpdate = () => {
  const location = useLocation();
  const videoGallery = location.state.videoGallery;
  const initialValues = {
    videoTitle: videoGallery.videoTitle,
    videoPath: videoGallery.videoPath,
    categoryId: videoGallery.categoryId,
    city: videoGallery.city,
    place: videoGallery.place,
    state: videoGallery.state,
    doc: videoGallery.doc,
    description: videoGallery.description,
  };

  const validationSchema = Yup.object({
    videoTitle: Yup.string().required("Video Title is required"),
    videoPath: Yup.string().required("Video Path is required"),
    categoryId: Yup.number().required("Category ID is required"),
    city: Yup.string().required("City is required"),
    place: Yup.string().required("Place is required"),
    state: Yup.string().required("State is required"),
    doc: Yup.date().required("Date is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.put(`https://vrindavanaashram.com/video/${videoGallery.id}`, values);
      alert("Video gallery updated successfully");
    } catch (error) {
      console.error(error);
      alert("Error updating video gallery");
    } finally {
      setSubmitting(false);
    }
  };

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get("https://vrindavanaashram.com/category");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="page-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-container">
            <p className="display-5">Update Video</p>
            <BootstrapForm.Group controlId="videoTitle">
              <FormLabel>Video Title</FormLabel>
              <Field as={FormControl} type="text" name="videoTitle" />
              <ErrorMessage
                name="videoTitle"
                component={Alert}
                variant="danger"
              />
            </BootstrapForm.Group>

            <BootstrapForm.Group controlId="videoPath">
              <FormLabel>Video Path</FormLabel>
              <Field as={FormControl} type="text" name="videoPath" />
              <ErrorMessage
                name="videoPath"
                component={Alert}
                variant="danger"
              />
            </BootstrapForm.Group>

            <BootstrapForm.Group controlId="categoryId">
              <FormLabel>Category ID</FormLabel>
              <Field
                as="select"
                name="categoryId"
                style={{ padding: "5px 50px", color: "black" }}
              >
                <option key={0} value={""}>
                  Select a category
                </option>
                {categories.map((category, i) => (
                  <option key={i + 1} value={category.id}>
                    {category.c_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="categoryId"
                component={Alert}
                variant="danger"
              />
            </BootstrapForm.Group>

            {/* 
            <BootstrapForm.Group controlId="categoryId">
              <FormLabel>Category ID</FormLabel>
              <Field as={FormControl} type="text" name="categoryId" />
              <ErrorMessage
                name="categoryId"
                component={Alert}
                variant="danger"
              />
            </BootstrapForm.Group> */}

            <BootstrapForm.Group controlId="city">
              <FormLabel>City</FormLabel>
              <Field as={FormControl} type="text" name="city" />
              <ErrorMessage name="city" component={Alert} variant="danger" />
            </BootstrapForm.Group>

            <BootstrapForm.Group controlId="place">
              <FormLabel>Place</FormLabel>
              <Field as={FormControl} type="text" name="place" />
              <ErrorMessage name="place" component={Alert} variant="danger" />
            </BootstrapForm.Group>

            <BootstrapForm.Group controlId="state">
              <FormLabel>State</FormLabel>
              <Field as={FormControl} type="text" name="state" />
              <ErrorMessage name="state" component={Alert} variant="danger" />
            </BootstrapForm.Group>

            <BootstrapForm.Group controlId="doc">
              <FormLabel>Date</FormLabel>
              <Field as={FormControl} type="date" name="doc" />
              <ErrorMessage name="doc" component={Alert} variant="danger" />
            </BootstrapForm.Group>

            <BootstrapForm.Group controlId="description">
              <FormLabel>Description</FormLabel>
              <Field as={FormControl} type="text" name="description" />
              <ErrorMessage
                name="description"
                component={Alert}
                variant="danger"
              />
            </BootstrapForm.Group>

            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
