import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link, Outlet } from "react-router-dom";
import logo from "../assets/images/Shreya-shray-logo.png";
import "../assets/css/Menu.css";
import { FaBars, FaSearch } from "react-icons/fa";
import "./style/menu.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

const Menu = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        variant="primary"
        className="btn btn-primary nav-btn border"
        onClick={handleShow}
      >
        <FaBars />
      </Button>
      <Offcanvas show={show} onHide={handleClose} backdrop={true}>
        <div className="menu-bg"  >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row" >
              <div className="col-lg-12 col-sm-12">
                <div className="row menu-top">
                  <div className="col d-flex p-2 justify-content-center">
                    <Nav.Link as={Link} to="/" onClick={handleClose}>
                      <img
                        src={require("../assets/images/buttons/menu/home.png")}
                      />
                    </Nav.Link>
                  </div>
                  <div className="col d-flex justify-content-center">
                    <Nav.Link as={Link} to="/room">
                      <img
                        src={require("../assets/images/buttons/menu/roombook.png")}
                      />
                    </Nav.Link>
                  </div>
                </div>
                <div className="row menu-mid">
                  <div className="col d-flex justify-content-center p-2">
                    <Nav.Link as={Link} to="/gallary" onClick={handleClose}>
                      <img
                        src={require("../assets/images/buttons/menu/photogallary.png")}
                      />
                    </Nav.Link>
                  </div>
                  <div className="col d-flex justify-content-center">
                    <Nav.Link as={Link} to="/videos" onClick={handleClose}>
                      <img
                        src={require("../assets/images/buttons/menu/videosgallary.png")}
                      />
                    </Nav.Link>
                  </div>
                </div>
                <div className="row menu-bottom mb-5">
                  <div className="col d-flex justify-content-center">
                    <Nav.Link as={Link} to="/kathabook"  onClick={handleClose}>
                      <img
                        src={require("../assets/images/buttons/menu/katha.png")}
                      />
                    </Nav.Link>
                  </div>
                  {/* <div className="col">
                    <Nav.Link as={Link} to="/BookRoom">
                      <img
                        src={require("../assets/images/buttons/menu/roombook.png")}
                      />
                    </Nav.Link>
                  </div> */}
                </div>
              </div>

              <div className="col-lg-12 col-sm-12">
               <div className="row contact-menu-row">
                <div className="col-6 logo-menu d-flex justify-content-center align-item-center">
                <img
                  src={require("../assets/images/Logo/logo1.png")}
                  height="10%"
                  className="mb-3"
                />
                </div>
                <div className="col-5 contact-menu-col d-flex justify-content-center align-item-center">
                  <div className="inner-contact-menu d-flex justify-content-center">
                    <Nav.Link as={Link} to="/about"  onClick={handleClose}>
                  <img
                    src={require("../assets/images/buttons/menu/about.png")}
                   
                    className="mb-3"
                  />
                  </Nav.Link>
                  <Nav.Link as={Link} to="/contact"  onClick={handleClose}>
                  <img
                    src={require("../assets/images/buttons/menu/contact.png")}
                    height="100"
                       className="mb-3"s
                  />
                  </Nav.Link>
                  <Nav.Link as={Link} to="/donate"  onClick={handleClose}>
                  <img
                    src={require("../assets/images/buttons/menu/donate.png")}
                    height="100"
                    
                  />
                  </Nav.Link>
                  
                  </div>
                </div>
               </div>
                
                
                 
              </div>
            </div>

            {/* <Link to="/" onClick={handleClose}>
            Home
          </Link>
          <br />
          <Link to="/videos" onClick={handleClose}>
            Videos
          </Link> */}
          </Offcanvas.Body>
        </div>
      </Offcanvas>
      <Outlet />
    </>
  );
};

export default Menu;
