import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import DeleteButton  from './DeleteButton';
import {BookingUpdateForm} from './UpdateBookings';
import "../../assets/css/admin/forms.css"
import UpdateButton from './UpdateButton';


const BookingUserFetchForm = () => {
  const [bookingUsers, setBookingUsers] = useState([]);

  useEffect(() => {
    const fetchBookingUsers = async () => {
      try {
        const response = await axios.get('https://vrindavanaashram.com/book/bookingUsers');
        setBookingUsers(response.data);
      } catch (error) {
        console.error(error);
        alert('Error fetching booking users');
      }
    };

    fetchBookingUsers();
  }, []);

  return (
    <div className='page-fetch'>
      <p className='display-5'>Booking Users</p>
      <Table striped bordered hover className='form-container'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Room ID</th>
            <th>Email</th>
            <th>Password</th>
            <th>Check-in Date</th>
            <th>Check-out Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
     
          {bookingUsers.map(bookingUser => (
            <tr key={bookingUser.id}>
              <td>{bookingUser.id}</td>
              <td>{bookingUser.name}</td>
              <td>{bookingUser.mobile}</td>
              <td>{bookingUser.roomId}</td>
              <td>{bookingUser.email}</td>
              <td>{bookingUser.password}</td>
              <td>{bookingUser.checkInDate}</td>
              <td>{bookingUser.checkOutDate}</td>
              <td>
                <UpdateButton bookingUser={bookingUser}/>
                <DeleteButton id={bookingUser.id} />

                
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export  {BookingUserFetchForm};
