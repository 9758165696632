import { MdCall, MdWhatsapp } from "react-icons/md";

import CalendarReact from "react-calendar";
import { useNavigate } from "react-router-dom";
import { InnerKathaBookingsPart, MainPageKathaBookings } from "./indexPage/MainPageKathaBookings";

export const BookkathaContact = () => {
    const navigate=useNavigate()
    const goToGallery=()=>
    {
      navigate("/gallary")
    }
  return (
    <>
    
     
       <div className="row bg-right">
       <h1 className="Headingh1">Book Katha</h1>
        <div className="col-lg-2 col-sm-12 ">
                <ul className="list-group list-group-flush rounded room-booking">
                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#E1AC5B",
                        fontSize: "150%",
                        fontFamily: " Italianno, cursive",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      Welcome to Shreya Ashram
                    </strong>

                    <p
                      style={{
                        color: "#ff1212",
                        fontSize: "150%",
                        fontFamily: " Italianno, cursive",
                        textAlign: "center",
                        marginBottom: "0",
                      }}
                    >
                      Book Katha
                    </p>

                    <hr
                      style={{
                        padding: "0",
                        marginTop: "0",
                        background: "yellow",
                        height: "2px",
                      }}
                    />
                  </li>
                  <li className="list-group-item text-center">
                    <p
                      style={{
                        color: "#ff0E0E",
                        fontSize: "150%",
                        paddingRight: "5px",
                        fontFamily: " Italianno, cursive",
                        letterSpacing: "2px",
                        textAlign: "center",
                        lineHeight: "1",
                        marginTop: "0",
                      }}
                    >
                      If you're interested in booking Katha,
                      please contact us using the numbers below:
                    </p>
                  </li>

                  <li className="list-group-item text-center">
                    <strong
                      style={{
                        color: "#ff0E0E",
                        fontSize: "150%",
                       
                      }}
                    >
                      <MdCall
                        style={{
                          color: "lightgreen",
                          fontSize: "180%",
                         
                        }}
                      />
                      <MdWhatsapp
                        style={{
                          color: "lightgreen",
                          fontSize: "180%",
                         
                        }}
                      />
                      <br/>
                      <strong
                        style={{
                          color: "#E1AC5B",
                          fontSize: "80%",
                          fontFamily: "Italianno, cursive",
                          letterSpacing: "2px",
                        }}
                      >
                        +91-9897619231<br/>+91-7983887273
                      </strong>
                    </strong>
                  </li>

               
                  
                </ul>
              
        </div>
        <div className="col p-0">
                  <InnerKathaBookingsPart/>
        </div>
    

       </div>
      
    </>
  );
};


const BookProcess=()=>
{
    return(<>

    </>)
}