import { MdCall, MdWhatsapp } from "react-icons/md";

import CalendarReact from "react-calendar";
import { useNavigate } from "react-router-dom";
import { InnerKathaBookingsPart, MainPageKathaBookings } from "./indexPage/MainPageKathaBookings";
import { MainPageLocation } from "./indexPage/MainPageLocation";

export const ContactUs = () => {
  
  return (
    <>
    
     
       <div className="row bg-right">
       <h1 className="Headingh1">Contact us</h1>
     
        <div className="col p-0">
                  <MainPageLocation/>
        </div>
    

       </div>
      
    </>
  );
};


const BookProcess=()=>
{
    return(<>

    </>)
}