import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import { DeleteButton } from "./DeleteButton";
import { VideoGalleryUpdate } from "./VideoGalleryUpdate";
import "../../assets/css/admin/forms.css";
import UpdateButton from "./UpdateButton";

export const VideoGalleryFetch = () => {
  const [videoGalleries, setVideoGalleries] = useState([]);

  useEffect(() => {
    fetchVideoGalleries();
  }, []);

  const fetchVideoGalleries = async () => {
    try {
      const response = await axios.get("https://vrindavanaashram.com/video");
      setVideoGalleries(response.data);
    } catch (error) {
      console.error(error);
      alert("Error fetching video galleries");
    }
  };

  return (
    <div className="page-fetch">
      <p className="display-5">Images</p>
      <Table striped bordered hover className="form-container">
        <thead>
          <tr>
            <th>ID</th>
            <th>Video Title</th>
            <th>Video Path</th>
            <th>Category ID</th>
            <th>City</th>
            <th>Place</th>
            <th>State</th>
            <th>DOC</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {videoGalleries.map((videoGallery) => (
            <tr key={videoGallery.id}>
              <td>{videoGallery.id}</td>
              <td>{videoGallery.videoTitle}</td>
              <td>{videoGallery.videoPath}</td>
              <td>{videoGallery.categoryId}</td>
              <td>{videoGallery.city}</td>
              <td>{videoGallery.place}</td>
              <td>{videoGallery.state}</td>
              <td>{videoGallery.doc}</td>
              <td>{videoGallery.description}</td>
              <td>
                <UpdateButton videoGallery={videoGallery} />
                <DeleteButton id={videoGallery.id} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
