import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import { ImageUpdate } from "./ImageUpdate";
import { ImageDelete } from "./ImageDelete";
import UpdateButton from "./UpdateButton";
import "../../assets/css/admin/forms.css"


export const ImageFetch = () => {
  const [imageGalleries, setImageGalleries] = useState([]);

  useEffect(() => {
    async function fetchImageGalleries() {
      try {
        const response = await axios.get("https://vrindavanaashram.com/image");
        setImageGalleries(response.data);
      } catch (error) {
        console.error(error);
        alert("Error fetching image galleries");
      }
    }

    fetchImageGalleries();
  }, []);

  const handleDelete = (deletedId) => {
    setImageGalleries(imageGalleries.filter((image) => image.id !== deletedId));
  };

  return (
    <div className='page-fetch'>
      <p className='display-5'>Images</p>
      <Table striped bordered hover className='form-container'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Image Title</th>
            <th>Image Path</th>
            <th>Category ID</th>
            <th>City</th>
            <th>Place</th>
            <th>State</th>
            <th>Date</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {imageGalleries.map((imageGallery) => (
            <tr key={imageGallery.id}>
              <td>{imageGallery.id}</td>
              <td>{imageGallery.imagetitle}</td>
              <td>{imageGallery.imagepath}</td>
              <td>{imageGallery.categoryId}</td>
              <td>{imageGallery.city}</td>
              <td>{imageGallery.place}</td>
              <td>{imageGallery.state}</td>
              <td>{imageGallery.doc}</td>
              <td>{imageGallery.description}</td>
              <td>
                <ImageDelete imageGallery={imageGallery} />
                <UpdateButton imageGallery={imageGallery} btnText="Update" />

                {/* <ImageDelete imageGallery={imageGallery} onDelete={handleDelete} /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
