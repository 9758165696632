import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, FormGroup, FormControl, FormLabel, FormText, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import axios from 'axios';
import "../../assets/css/admin/forms.css"

export const AddCategory = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.post('https://vrindavanaashram.com/category', values);
      alert('Category inserted successfully');
    } catch (error) {
      console.error(error);
      alert('Error inserting category');
    }
    setSubmitting(false);
  };

  return (
    <div className='page-container'>
    <Formik
      initialValues={{ c_name: '' }}
      validationSchema={Yup.object().shape({
        c_name: Yup.string().required('Category name is required')
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched }) => (
         <Form className='form-container'>
         <p className='display-5'>Add New Category</p>
          <FormGroup>
            <FormLabel>Category Name</FormLabel>
            <Field name="c_name" as={FormControl} />
            {errors.c_name && touched.c_name && <FormText>{errors.c_name}</FormText>}
          </FormGroup>

          <Button type="submit" disabled={isSubmitting}>Add New Category</Button>

          {isSubmitting && <Alert variant="info">Submitting...</Alert>}
        </Form>
      )}
    </Formik>
    </div>
  );
};

